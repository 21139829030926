<template>
    <div class="page-body edit-module-modal">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Edit Module</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0" @submit.prevent="save">
                    <div v-if="!loading" class="d-flex flex-column align-items-center gap-4">
                        <div class="w-75 mx-4 app-img-upload">
                            <label for="file" class="class-label">Module Icon*</label>
                            <div class="input-add-new-dashboard mt-3 d-flex align-items-center input-class">
                                <span class="px-3" style="color: #747373; flex: 1"> Upload Image </span>
                                <button
                                    type="button"
                                    class="no-bg text-button choose-file-btn my-1 mx-2 py-2"
                                    @click="chooseFile"
                                >
                                    Choose file
                                </button>
                                <input
                                    id="file"
                                    type="file"
                                    name="upload"
                                    ref="upload"
                                    size="100000"
                                    @change="uploadFile"
                                    hidden
                                    accept=".png, .jpg, .jpeg"
                                    :disabled="showImgLoader"
                                />
                                <div
                                    class="img-container-small d-flex justify-content-center align-items-center m-1"
                                    style="margin-right: 20px !important"
                                >
                                    <img src="@/assets/px/drag-and-drop/dummyUploadImage.svg" />

                                    <img
                                        v-if="uploadedImg && !showImgLoader"
                                        :src="uploadedImg"
                                        alt="image not uploaded"
                                    />

                                    <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                        <div class="d-flex justify-content-center align-items-center img-loader-main">
                                            <b-spinner variant="primary" class="img-loader"></b-spinner>
                                        </div>
                                        <span class="img-loader-text">Uploading...</span>
                                    </div>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="top"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined img-delete-small"
                                            role="button"
                                            @click="resetFileUpload()"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="w-75 mx-4">
                            <Input
                                label="Module Name"
                                name="Module Name"
                                id="moduleNameInput"
                                placeholder="Enter Module name"
                                inputClass="input-add-new-dashboard mt-3 input-class"
                                labelClass="class-label"
                                v-model="moduleDetails.secondary_function_name"
                                :input_style="'text-transform:capitalize'"
                                required
                            />
                        </div>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="submit" class="primary btn-save" :disabled="showImgLoader">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile, dashboardBuilder } from '@/util/apiRequests';

    export default {
        name: 'EditProfileFunctionModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                showImgLoader: false,
                uploadedImg: null,
                moduleDetails: {
                    secondary_function_name: '',
                    image: null,
                },
            };
        },
        methods: {
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.moduleDetails.image = '';
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    // HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (event) => {
                            this.uploadedImg = event.target.result;
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.moduleDetails.image = uploadImgResponse.data.s3_link;
                            this.moduleDetails.imageUrl = await this.geturl(uploadImgResponse.data.s3_link);
                            this.showImgLoader = false;
                            this.$toasted.success('Image uploaded successfully !');
                        };
                    } catch (e) {
                        this.showImgLoader = false;
                        this.$refs.upload.value = '';
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                    }
                }
            },
            async save() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                if (!this.moduleDetails?.image) {
                    this.$toasted.error('Please upload image');
                    return;
                }
                this.$emit('save', this.moduleDetails);
            },
        },
        async created() {
            if (this.profileFunction) {
                this.moduleDetails = { ...this.profileFunction };
                this.uploadedImg = this.moduleDetails.imageUrl;
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .choose-file-btn,
    .choose-file-btn:hover {
        width: 9rem;
        border-radius: 5px;
        background: #5155c3 !important;
        color: white !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .edit-module-modal .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
        width: 35vw !important;
    }

    .edit-module-modal .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }

    .edit-module-modal .input-class,
    #moduleNameInput {
        box-shadow: inset 0 0 0 1px #707070;
        border: none !important;
        border-radius: 5px !important;
    }
</style>
