var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NoButtonModal',{attrs:{"open":_vm.open,"id":"the_new_client_dropdown_modal overflow-scroll"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-list-third-party-app"},[_c('ejs-tooltip',{staticClass:"tooltipcontainer",attrs:{"content":"Close","target":"#target","cssClass":"customtooltip"}},[_c('span',{staticClass:"material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle",attrs:{"id":"target","role":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("close")])]),_c('div',[_c('div',{staticClass:"droppable-template"},[_c('div',{staticClass:"primeContainer",style:({ background: _vm.pageData.page3.appColors.background_color })},[_c('div',{staticClass:"dashboard-top-bar",style:({
                            background: _vm.pageData.page3.appColors.canvas_color,
                            color: _vm.pageData.page3.appColors.canvas_text_color,
                        })},[_c('div',{staticClass:"inner-dashboard-top-bar"},[_c('div',{staticClass:"d-flex w-10 justify-content-start"},[_c('div',{staticClass:"d-flex justify-content-start ms-3"},[_c('img',{staticStyle:{"width":"30px","height":"30px","filter":"invert(1) grayscale(100%) brightness(200%)"},attrs:{"src":require("@/assets/px/drag-and-drop/menu-icon.svg"),"alt":""}})])]),_c('div',{staticClass:"w-55 d-flex fw-semibold fs-4"},[_vm._v("Dashboard")])]),_c('div',{staticClass:"dashboard-header"},[_vm._v(" Hello, Adam Liviston "),_c('img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("@/assets/px/header-icons-images/hello.svg"),"alt":""}})])]),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"margin-top":"5rem"}},[_c('div',{staticClass:"module-heading",style:({ color: _vm.pageData.page3.appColors.background_text_color })},[_vm._v(" Offered Services ")]),_c('div',{staticClass:"u-l-c upperLayoutContainer"},_vm._l((_vm.paginatedItems('LI')),function(item){return _c('div',{key:item[1].name + '',staticClass:"upperLayoutContainerItemCommon",style:(item[1].style)},_vm._l((_vm.pageData.page4.template['LI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class},[(value.function_type == 'generic')?_c('div',{staticClass:"widget-img",style:({
                                            height:
                                                _vm.pageData.page4.template['LI'][item[0]].title.length == 0
                                                    ? '100%'
                                                    : '7rem',
                                        })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(_vm.pageData.page4.template['LI'][item[0]].title)?_c('p',[_vm._v(" "+_vm._s(_vm.pageData.page4.template['LI'][item[0]].title)+" ")]):_vm._e()])])}),0)}),0),(_vm.totalPages('LI') > 1)?_c('div',{staticClass:"mt-4 flex align-items-center justify-center gap-2"},_vm._l((_vm.totalPages('LI')),function(page){return _c('div',{key:page,staticClass:"pagination cursor-pointer",style:({
                                    background:
                                        page === _vm.currentLIPage
                                            ? _vm.pageData.page3.appColors.canvas_color
                                            : _vm.pageData.page3.appColors.canvas_text_color,
                                }),on:{"click":function($event){return _vm.handlePageChange('LI', page)}}})}),0):_vm._e()]),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"padding":"0 2rem 2rem"}},[_c('div',{staticClass:"module-heading",style:({ color: _vm.pageData.page3.appColors.background_text_color })},[_vm._v(" Informational Links ")]),_c('div',{staticClass:"u-l-c upperLayoutContainer grid-container"},_vm._l((_vm.paginatedItems('GSI')),function(item){return _c('div',{key:item[1].name + '',staticClass:"upperLayoutContainerItemCommon",style:(item[1].style)},_vm._l((_vm.pageData.page4.template['GSI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class},[(value.function_type == 'generic')?_c('div',{staticClass:"widget-img",style:({
                                            height:
                                                _vm.pageData.page4.template['GSI'][item[0]].title.length == 0
                                                    ? '100%'
                                                    : '7rem',
                                        })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(_vm.pageData.page4.template['GSI'][item[0]].title)?_c('p',[_vm._v(" "+_vm._s(_vm.pageData.page4.template['GSI'][item[0]].title)+" ")]):_vm._e()])])}),0)}),0),(_vm.totalPages('GSI') > 1)?_c('div',{staticClass:"mt-4 flex align-items-center justify-center gap-2"},_vm._l((_vm.totalPages('GSI')),function(page){return _c('div',{key:page,staticClass:"pagination cursor-pointer",style:({
                                    background:
                                        page === _vm.currentGSIPage
                                            ? _vm.pageData.page3.appColors.canvas_color
                                            : _vm.pageData.page3.appColors.canvas_text_color,
                                }),on:{"click":function($event){return _vm.handlePageChange('GSI', page)}}})}),0):_vm._e()])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }