<template>
    <NoButtonModal :open="open" @close="$emit('close')" id="the_new_client_dropdown_modal overflow-scroll">
        <div class="modal-list-third-party-app">
            <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                <span
                    id="target"
                    class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                    role="button"
                    @click="$emit('close')"
                    >close</span
                >
            </ejs-tooltip>
            <div>
                <div class="droppable-template">
                    <div class="primeContainer" :style="{ background: pageData.page3.appColors.background_color }">
                        <div
                            class="dashboard-top-bar"
                            :style="{
                                background: pageData.page3.appColors.canvas_color,
                                color: pageData.page3.appColors.canvas_text_color,
                            }"
                        >
                            <div class="inner-dashboard-top-bar">
                                <div class="d-flex w-10 justify-content-start">
                                    <div class="d-flex justify-content-start ms-3">
                                        <img
                                            src="@/assets/px/drag-and-drop/menu-icon.svg"
                                            alt=""
                                            style="
                                                width: 30px;
                                                height: 30px;
                                                filter: invert(1) grayscale(100%) brightness(200%);
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="w-55 d-flex fw-semibold fs-4">Dashboard</div>
                                <!-- <div class="d-flex w-25 gap-3 justify-content-end align-items-center">
                                    <div class="rounded-3 p-sm-1" style="background-color: #3a88bd">
                                        <img
                                            src="@/assets/px/header-icons-images/femaleUserIcon.png"
                                            alt=""
                                            style="height: 25px; width: 25px"
                                        />
                                    </div>
                                    <div class="rounded-3 p-sm-1" style="background-color: #3a88bd">
                                        <img
                                            src="@/assets/px/header-icons-images/globe-language.svg"
                                            alt=""
                                            style="height: 25px; width: 25px"
                                        />
                                    </div>
                                    <img
                                        src="@/assets/px/header-icons-images/notification.svg"
                                        alt=""
                                        style="
                                            height: 25px;
                                            width: 25px;
                                            filter: invert(1) grayscale(100%) brightness(200%);
                                        "
                                    />
                                </div> -->
                            </div>
                            <div class="dashboard-header">
                                Hello, Adam Liviston
                                <img
                                    src="@/assets/px/header-icons-images/hello.svg"
                                    alt=""
                                    style="width: 40px; height: 40px"
                                />
                            </div>
                        </div>
                        <div class="t-m-c templateMainContainer" style="margin-top: 5rem">
                            <div
                                class="module-heading"
                                :style="{ color: pageData.page3.appColors.background_text_color }"
                            >
                                {{ pageData.page4.sectionTitle }}
                            </div>
                            <div class="u-l-c upperLayoutContainer">
                                <div
                                    v-for="item in paginatedItems('LI')"
                                    class="upperLayoutContainerItemCommon"
                                    :style="item[1].style"
                                    :key="item[1].name + ''"
                                >
                                    <div
                                        :class="value.class"
                                        class="module"
                                        v-for="value in pageData.page4.template['LI'][item[0]].elements"
                                        :key="value.name"
                                    >
                                        <div
                                            v-if="value.function_type == 'generic'"
                                            class="widget-img"
                                            :style="{
                                                height:
                                                    pageData.page4.template['LI'][item[0]].title.length == 0
                                                        ? '100%'
                                                        : '7rem',
                                            }"
                                        >
                                            <img :src="value.image" alt="" />
                                        </div>
                                        <div v-else class="widget-img" style="height: 7rem">
                                            <img :src="value.image" alt="" />
                                        </div>
                                        <div class="widget-title">
                                            <p v-if="pageData.page4.template['LI'][item[0]].title">
                                                {{ pageData.page4.template['LI'][item[0]].title }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Pagination Indicators -->
                            <div v-if="totalPages('LI') > 1" class="mt-4 flex align-items-center justify-center gap-2">
                                <div
                                    v-for="page in totalPages('LI')"
                                    :key="page"
                                    class="pagination cursor-pointer"
                                    :style="{
                                        background:
                                            page === currentLIPage
                                                ? pageData.page3.appColors.canvas_color
                                                : pageData.page3.appColors.canvas_text_color,
                                    }"
                                    @click="currentLIPage = page"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'PreviewModalHealthHome',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            selected: {
                type: String,
            },
            pageData: {
                type: Object,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentLIPage: 1,
            };
        },
        methods: {
            totalPages(group) {
                return Math.ceil(Object.keys(this.pageData.page4.template[group]).length / 6);
            },
            paginatedItems(group) {
                const startIndex = (this.currentLIPage - 1) * 6;
                const endIndex = startIndex + 6;
                return Object.entries(this.pageData.page4.template[group]).slice(startIndex, endIndex);
            },
        },
    };
</script>

<style scoped>
    .primeContainer {
        width: auto;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
        --mini-display-width-numeric: 500;
        --upper-layout-container-height: 72%;
        --bottom-info-container-height: 6%;
    }

    .upperLayoutContainerItemCommon {
        background-color: rgba(255, 255, 255, 0.85);
        position: unset;
        margin: auto;
        height: 10rem !important;
        width: 11rem !important;
        border-radius: 2.5rem !important;
        height: 10rem;
        width: 11rem;
        border-radius: 2.5rem;
    }

    .templateMainContainer {
        padding: 7rem 2rem 2rem 2rem;
        width: auto;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }

    .upperLayoutContainer {
        width: 100%;
        display: grid;
        align-content: center;
        position: relative;
        grid-template-columns: repeat(2, 3fr);
        grid-template-rows: repeat(3, 2fr);
        height: var(--upper-layout-container-height);
        gap: calc((5rem * (var(--mini-display-width-numeric) / 1400)));
    }

    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .module {
        position: relative;
        text-align: -webkit-center;
        height: 100%;
    }

    .widget-img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .widget-img > img {
        max-height: 80%;
        max-width: 80%;
    }

    .widget-title > p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: medium;
        text-align: center;
    }

    .dashboard-top-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10rem;
        border-radius: 0 0 5rem 5rem;
    }

    .inner-dashboard-top-bar {
        height: 4.3rem;
        width: 100%;
        left: 0px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .dashboard-header {
        font-weight: 700;
        display: flex;
        justify-content: center;
        font-size: 25px;
        margin-top: 10px;
        align-items: center;
        gap: 10px;
    }

    .module-heading {
        text-transform: capitalize;
        font-size: 2rem;
        z-index: 0;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
    }

    .upload-btn {
        font-size: 1.2rem;
        border-radius: 20px;
        color: #fff;
        background: #1773b1;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .pagination {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
</style>
