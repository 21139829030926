<template>
    <div class="third-party-launcher-modal">
        <NoButtonModal :open="open" @close="cancel" id="the_new_client_dropdown_modal">
            <div class="modal-list-third-party-app" v-if="showAppListing">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <span class="label-app-list-header">List of third party application</span>
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- Now we Handle all CRUD operation for 3rd party application from diffrent section-->
                        <!-- <div>
                            <button
                                type="button"
                                class="no-bg text-button btn-add-new-app"
                                @click="showPage('ADD_FUNCTION')"
                            >
                                Add new app
                            </button>
                        </div> -->
                        <ejs-tooltip class="tooltipcontainer" content="Close" target="#target" cssClass="customtooltip">
                            <span
                                id="target"
                                class="material-symbols-outlined btn-popup-close ms-2 mt-1"
                                role="button"
                                @click="cancel"
                                >close</span
                            >
                        </ejs-tooltip>
                    </div>
                </div>
                <template v-if="!showLoader">
                    <div class="section-body content-checkbox-list">
                        <template v-if="thirdPartyFunctionList.length > 0">
                            <div
                                v-for="thirdPartyFunction in thirdPartyFunctionList"
                                :key="thirdPartyFunction.id"
                                class="d-flex justify-content-between"
                            >
                                <Checkbox
                                    class="block field-container d-flex align-items-center my-2 w-100"
                                    :name="thirdPartyFunction.function_name"
                                    :id="'launcher_modal_' + thirdPartyFunction.function_name"
                                    :label="thirdPartyFunction.function_name"
                                    :value="thirdPartyFunction.active"
                                    labelClass="app-list-label-class"
                                    @input="(value) => onCheckUncheck(value, thirdPartyFunction)"
                                />
                                <!-- <span>{{ application }}</span>
                            <input class="color" type="checkbox" v-model="selectedApplications"/> -->
                                <!-- <div class="d-flex">
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Edit"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-edit me-2"
                                            role="button"
                                            @click="editThirdPartyApp(thirdPartyFunction.id)"
                                        >
                                            edit_square
                                        </span>
                                    </ejs-tooltip>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-delete"
                                            role="button"
                                            @click="deleteThirdPartyApp(thirdPartyFunction)"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div> -->
                            </div>
                        </template>
                        <template v-if="thirdPartyFunctionList.length === 0">
                            <div class="no-app-found d-flex flex-column justify-content-center align-items-center">
                                <span class="no-app-found-heading">No 3rd party applications added !</span>
                                <span class="no-app-found-reason"
                                    >Get started with your very first 3rd party application integration by clicking on
                                    Add app button</span
                                >
                            </div>
                        </template>
                    </div>
                    <div class="section-footer my-2">
                        <div class="d-flex justify-content-center">
                            <button
                                type="button"
                                class="primary btn-save-application"
                                :disabled="thirdPartyFunctionList.length === 0"
                                @click="saveApplications"
                            >
                                Save
                            </button>
                            <button type="button" class="no-bg text-button btn-cancel px-4" @click="cancel">
                                Cancel
                            </button>
                        </div>
                    </div>
                </template>
            </div>
            <div class="modal-list-third-party-app" v-if="!showAppListing">
                <ValidationObserver ref="form">
                    <form>
                        <div class="section-header d-flex justify-content-between align-items-center">
                            <span class="label-app-list-header px-4">{{
                                isEditScreen ? 'Edit Application' : 'Add New Application'
                            }}</span>
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Close"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <span
                                    id="target"
                                    class="material-symbols-outlined btn-popup-close me-2 mt-1"
                                    role="button"
                                    @click="showPage('FUNCTION_LISTING')"
                                    >close</span
                                >
                            </ejs-tooltip>
                        </div>
                        <template v-if="!showLoader">
                            <div class="section-body add-new-app-form">
                                <Input
                                    label="Android App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="Android App ID"
                                    placeholder="Example: us.zoom.videomeeting"
                                    v-model="functionInfo.androidId"
                                />
                                <Input
                                    label="IOS App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                    name="iosAppID"
                                    placeholder="Example: us.zoom.video.meeting"
                                    v-model="functionInfo.iosId"
                                />
                                <Input
                                    label="Windows App ID"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="Windows App ID"
                                    placeholder="Example: us.zoom.videomeeting"
                                    v-model="functionInfo.windowsId"
                                />
                                <Input
                                    label="Name"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="name"
                                    placeholder="Example: zoom"
                                    v-model="functionInfo.functionName"
                                    required
                                />
                                <div class="app-img-upload block field-container d-flex align-items-center">
                                    <label class="me-3 d-flex justify-content-end mb-5 add-new-app-label"
                                        >Icon Art*</label
                                    >
                                    <div class="d-flex align-items-center">
                                        <div class="img-container d-flex justify-content-center align-items-center">
                                            <img
                                                v-if="!uploadedImg && !showImgLoader"
                                                src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                            />

                                            <img
                                                v-if="uploadedImg && !showImgLoader"
                                                :src="uploadedImg"
                                                style="max-height: 100%"
                                                alt="image not uploaded"
                                            />
                                            <div class="d-flex flex-column" v-if="showImgLoader">
                                                <div
                                                    class="d-flex justify-content-center align-items-center img-loader-main"
                                                >
                                                    <b-spinner variant="primary" class="img-loader"></b-spinner>
                                                </div>
                                                <span class="img-loader-text">Uploading...</span>
                                            </div>
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Delete"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="top"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined img-delete"
                                                    role="button"
                                                    @click="resetFileUpload()"
                                                >
                                                    delete
                                                </span>
                                            </ejs-tooltip>
                                        </div>

                                        <button
                                            type="button"
                                            class="no-bg text-button btn-add-new-app ms-3"
                                            @click="chooseFile"
                                        >
                                            Choose file
                                        </button>
                                        <!-- <span v-if="isFileAvailable" class="errorClass">Please upload file</span> -->
                                        <input
                                            id="fileid"
                                            type="file"
                                            name="upload"
                                            ref="upload"
                                            size="100000"
                                            @change="uploadFile"
                                            hidden
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="section-footer add-new-app-form my-4">
                                <div class="d-flex justify-content-center">
                                    <button
                                        v-if="!isEditScreen"
                                        type="button"
                                        class="primary btn-save-application"
                                        :disabled="showImgLoader"
                                        @click="addNewApplication"
                                    >
                                        Save Application
                                    </button>
                                    <button
                                        v-if="isEditScreen"
                                        type="button"
                                        class="primary btn-save-application"
                                        :disabled="showImgLoader"
                                        @click="updateApplication"
                                    >
                                        Save Application
                                    </button>
                                    <button type="button" class="no-bg text-button btn-cancel px-4" @click="resetAll()">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </template>
                    </form>
                </ValidationObserver>
            </div>
            <!-- ### Custom Loader ### -->
            <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile } from '@/util/apiRequests';

    const initialFunctionInfoState = {
        functionName: '',
        androidId: '',
        iosId: '',
        windowsId: '',
        image: '',
        functionType: '3rdParty',
        application: '',
        data: {
            title: '',
            description: '',
            link: '',
            feedlink: '',
            feeddirection: '',
            itemstodisplay: 0,
            includespage: 0,
        },
    };

    export default {
        name: 'LauncherModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            coreFunctionList: {
                type: Array,
                required: true,
            },
            showSaveSetting: {
                type: Boolean,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showAppListing: true,
                showLoader: false,
                isEditScreen: false,
                selectedApplications: '',
                applicationOptions: [
                    'Samsung Smart TV',
                    'Zoom Video Calling',
                    'Google Meet',
                    'Content Delivery Engine (CDE)',
                ],
                thirdPartyFunctionList: [],
                functionInfo: { ...initialFunctionInfoState },
                uploadedImg: '',
                showImgLoader: false,
            };
        },
        methods: {
            onCheckUncheck(value, thirdPartyFunction) {
                this.thirdPartyFunctionList.forEach((el) => {
                    if (el.id === thirdPartyFunction.id) {
                        el.active = value ? 1 : 0;
                    }
                });
            },
            chooseFile() {
                this.$refs.upload.click();
            },
            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            async addNewApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (
                        this.functionInfo.androidId.length == 0 &&
                        this.functionInfo.iosId.length == 0 &&
                        this.functionInfo.windowsId.length == 0
                    ) {
                        this.$toasted.error('Please fill any one of the app ID');
                        return;
                    }

                    if (!this.isFileAvailable()) {
                        this.$toasted.error('Please upload file');
                        return;
                    }
                    this.showLoader = true;
                    this.functionInfo.application = this.applicationFrame;

                    // HTTP call for add new 3rd party application
                    const endpoint = memberProfile.createFunction();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Application added successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async saveApplications() {
                try {
                    const isAnyFunctionSelected = this.thirdPartyFunctionList.some((el) => el.active);
                    const isCombinedWithCoreFunc = this.coreFunctionList.some((el) => el.function_type === '3rdParty');

                    if (!isAnyFunctionSelected && !isCombinedWithCoreFunc) {
                        this.$toasted.error('Please select at least one application !');
                        return;
                    }

                    this.$emit('onSaveThirdPartyApplication', this.thirdPartyFunctionList);
                    this.$emit('close');
                    this.$toasted.success('Third party application selections were saved successfully');
                } catch (e) {
                    this.$toasted.error('Failed to save applications !');
                }
            },
            async fetchApplications() {
                try {
                    this.showLoader = true;

                    // HTTP call for get 3rd party application list
                    const endpoint = memberProfile.getFunction('3rdParty');
                    const fetchFunctionResponse = await this.$api.get(endpoint);
                    if (!fetchFunctionResponse) {
                        throw 'e';
                    }

                    let { data } = fetchFunctionResponse.data;
                    this.thirdPartyFunctionList = data;

                    // Making default false for all functions
                    this.thirdPartyFunctionList.forEach((func) => (func.active = 0));

                    // Handling for edit profile case
                    let selectedThirdPartyFunctions = [];
                    selectedThirdPartyFunctions = this.coreFunctionList.filter(
                        (coreFunc) => coreFunc.function_type === '3rdParty'
                    );
                    if (!this.showSaveSetting && selectedThirdPartyFunctions.length > 0) {
                        selectedThirdPartyFunctions.forEach((func) => {
                            let foundFunc = this.thirdPartyFunctionList.find(
                                (thirdPartyFunc) => thirdPartyFunc.id === func.id
                            );
                            if (foundFunc) {
                                this.thirdPartyFunctionList.forEach((func) => {
                                    if (func.id === foundFunc.id) {
                                        func.active = 1;
                                    }
                                });
                            }
                        });
                    }
                } catch (e) {
                    this.$toasted.error('Failed to get applications !');
                } finally {
                    this.showLoader = false;
                }
            },
            async editThirdPartyApp(functionId) {
                try {
                    this.showLoader = true;

                    // HTTP call for get specific 3rd party application detail
                    const endpoint = memberProfile.getSpecificFunction(functionId, '3rdParty');
                    const functionResponse = await this.$api.get(endpoint);
                    if (!functionResponse) {
                        throw 'e';
                    }

                    let { data } = functionResponse.data;

                    this.isEditScreen = true;
                    this.showAppListing = false;
                    this.functionInfo = {
                        functionId: data[0].id,
                        functionName: data[0].function_name,
                        androidId: data[0].android_id,
                        iosId: data[0].ios_id,
                        windowsId: data[0].windows_id,
                        image: data[0].image,

                        functionType: '3rdParty',
                    };
                    this.uploadedImg = data[0].imageUrl ?? '';
                } catch (e) {
                    this.$toasted.error('Failed to get application details !');
                } finally {
                    this.showLoader = false;
                }
            },
            async updateApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!this.isFileAvailable() && !this.uploadedImg) {
                        this.$toasted.error('Please upload file');
                        return;
                    }
                    this.showLoader = true;

                    // HTTP call for update 3rd party application
                    const endpoint = memberProfile.updateFunction();
                    const saveFunctionResponse = await this.$api.put(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.$toasted.success('Application updated successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            deleteThirdPartyApp(thirdPartyFunction) {
                if (!thirdPartyFunction.id) {
                    this.$toasted.error(`Application id not found !`);
                    return;
                }

                if (thirdPartyFunction.active) {
                    this.$toasted.error(
                        `This application can't be deleted, since its selected. Please unselect and try again`
                    );
                    return;
                }

                this.$emit('onDeleteThirdParty', thirdPartyFunction.id);
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (file.size / 1024 / 1024 > 10) {
                        this.$toasted.error('Maximum 10 MB file size allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    //HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const formData = new FormData();
                        formData.append('file', file);

                        const endpoint = memberProfile.uploadProfileFunctionsImage();
                        const uploadImgResponse = await this.$api.post(endpoint, formData);
                        if (!uploadImgResponse.data.success) {
                            throw uploadImgResponse.data.error;
                        }

                        //Coverts into base64 for rendering
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            this.uploadedImg = event.target.result;
                        };

                        reader.readAsDataURL(file);

                        this.functionInfo.image = uploadImgResponse.data.s3_link;
                        this.$toasted.success('Application image uploaded successfully !');
                    } catch (e) {
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.$refs.upload.value = '';
                this.functionInfo.image = '';
            },
            showPage(pageType) {
                if (pageType === 'FUNCTION_LISTING') {
                    this.showAppListing = true;
                    this.isEditScreen = false;
                    this.fetchApplications();
                } else {
                    this.showAppListing = false;
                    this.resetForm();
                    this.uploadedImg = '';
                }
            },
            cancel() {
                this.showAppListing = true;
                this.isEditScreen = false;
                this.resetForm();
                this.$emit('close');
            },
            resetForm() {
                this.functionInfo = { ...initialFunctionInfoState };
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.form.reset();
                    });
                }
            },
            resetAll() {
                this.resetForm();
                this.resetFileUpload();
            },
        },
        created() {
            if (this.showAppListing) {
                this.fetchApplications();
            }
        },
    };
</script>
<style scoped>
    .loader-main {
        min-height: 50vh;
    }
    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }
    .img-loader-main {
        min-height: 30px;
    }
    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }
    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }
</style>

<style>
    .third-party-launcher-modal .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
