import api from '@/util/api';
import { education } from './apiRequests';

export const deleteAllEducationResourcesByDashboardId = async (application, dashboardId) => {
    try {
        const endpoint1 = education.deleteAllEducationResourcesByDashboardId(application, dashboardId);
        await api.put(endpoint1);
    } catch (err) {
        console.error(err);
        throw new Error('Failed to delete all education resources by dashboard id');
    }
};
