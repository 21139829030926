var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 my-5"},[_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"bg-white rounded-4 border mx-2 widget-list overflow-auto",staticStyle:{"height":"630px"}},[_c('h1',{staticClass:"px-4 py-3 heading"},[_vm._v("Selected Modules widgets")]),_c('draggable',{staticClass:"p-4 widgets-container",attrs:{"list":_vm.widgetsList,"group":{ name: 'myGroup', pull: true, put: false }},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd}},_vm._l((_vm.widgetsList),function(element,index){return _c('div',{key:index,staticClass:"col-4 d-flex justify-content-center"},[(
                            element.function_type === 'generic' &&
                            !['Mood Tracker', 'Contact'].includes(element.function_name)
                        )?_c('div',{staticClass:"widget"},[_c('img',{staticClass:"m-auto",attrs:{"src":element.image,"alt":""}})]):_c('div',{staticClass:"widget widget-title d-flex justify-content-center align-items-center flex-column gap-2"},[_c('img',{style:({
                                'max-height': ['Mood Tracker', 'Contact'].includes(element.function_name)
                                    ? '4rem'
                                    : '5rem',
                            }),attrs:{"src":element.image,"alt":""}}),_c('p',{staticClass:"fw-semibold"},[_vm._v(" "+_vm._s(element.function_name)+" ")])])])}),0)],1),_c('div',{staticClass:"bg-white rounded-4 border mx-2 dash-builder w-50 over",staticStyle:{"height":"630px","overflow-y":"auto"}},[_vm._m(0),(_vm.template)?_c('div',{staticClass:"droppable-template"},[_c('div',{staticClass:"primeContainer",style:({ background: _vm.appColors.background_color })},[_c('div',{staticClass:"dashboard-top-bar",style:({
                            background: _vm.appColors.canvas_color,
                            color: _vm.appColors.canvas_text_color,
                        })},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"margin-top":"5rem"}},[_c('div',{staticClass:"module-heading flex space-between",style:({ color: _vm.appColors.background_text_color })},[_c('span',[_vm._v(" Offered Services ")]),_c('span',{staticClass:"upload-btn px-3 py-2 d-flex gap-2 align-items-center cursor-pointer",on:{"click":function($event){return _vm.addItemsInTemplate('LI')}}},[_c('img',{staticStyle:{"max-height":"25px"},attrs:{"src":require("@/assets/px/add.png"),"alt":""}}),_vm._v(" Add Button")])]),_c('div',{staticClass:"u-l-c upperLayoutContainer"},_vm._l((_vm.paginatedItems('LI')),function(item,index){return _c('div',{key:item[1].name,class:'upperLayoutContainerItemCommon ' +
                                    item[1].class +
                                    (_vm.width && _vm.height && !item[1].elements.length ? ' highlighted-section ' : ''),style:(item[1].style)},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":item[1].elements,"group":item[1].elements.length
                                            ? { name: 'myGroup', pull: true, put: false }
                                            : { name: 'myGroup', pull: true, put: true },"options":{ accept: false }},on:{"start":_vm.onMoveStart,"end":_vm.onMoveEnd,"change":function($event){return _vm.manipulateWidgets($event, 'LI', item[1].name, 'add', item, index)}}},_vm._l((item[1].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class,on:{"mouseover":function($event){_vm.hoveredElementId = 'LI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){return _vm.addDetails(value.function_name, index, 'LI')}}},[(value.function_type === 'generic')?_c('div',{staticClass:"widget-img",style:({
                                                height: item[1].title.length === 0 ? '100%' : '7rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(item[1].title)?_c('p',[_vm._v(_vm._s(item[1].title))]):_vm._e()]),(_vm.hoveredElementId == 'LI' + index)?[_c('div',{staticClass:"w-100 h-100 action-buttons"},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                            $event,
                                                            'LI',
                                                            item[1].name,
                                                            'remove',
                                                            value,
                                                            index
                                                        )}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"delete","triggers":"hover","placement":"bottom"}},[_vm._v("Delete")]),_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value, index, 'LI')}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"edit","triggers":"hover","placement":"bottom"}},[_vm._v("Edit")])],1)]:_vm._e()],2)}),0)],1)}),0),(_vm.totalPages('LI') > 1)?_c('div',{staticClass:"mt-4 flex align-items-center justify-center gap-2"},_vm._l((_vm.totalPages('LI')),function(page){return _c('div',{key:page,staticClass:"pagination cursor-pointer",style:({
                                    background:
                                        page === _vm.currentLIPage
                                            ? _vm.appColors.canvas_color
                                            : _vm.appColors.canvas_text_color,
                                }),on:{"click":function($event){return _vm.handlePageChange('LI', page)}}})}),0):_vm._e()]),_c('div',{staticClass:"t-m-c templateMainContainer",staticStyle:{"padding":"0 2rem 2rem"}},[_c('div',{staticClass:"module-heading flex space-between",style:({ color: _vm.appColors.background_text_color })},[_c('span',[_vm._v(" Informational Links ")]),_c('span',{staticClass:"upload-btn px-3 py-2 d-flex gap-2 align-items-center cursor-pointer",on:{"click":function($event){return _vm.addItemsInTemplate('GSI')}}},[_c('img',{staticStyle:{"max-height":"25px"},attrs:{"src":require("@/assets/px/add.png"),"alt":""}}),_vm._v(" Add Button")])]),_c('div',{staticClass:"u-l-c upperLayoutContainer grid-container"},_vm._l((_vm.paginatedItems('GSI')),function(item,index){return _c('div',{key:item[1].name + '',class:'upperLayoutContainerItemCommon ' +
                                    item[1].class +
                                    (_vm.width && _vm.height && !item[1].elements.length ? ' highlighted-section ' : ''),style:(item[1].style)},[_c('draggable',{staticClass:"draggable-list",attrs:{"list":_vm.template['GSI'][item[0]].elements,"group":_vm.template['GSI'][item[0]].elements.length
                                            ? { name: 'myGroup', pull: true, put: false }
                                            : { name: 'myGroup', pull: true, put: true },"options":{ accept: false }},on:{"start":_vm.onMoveStart,"end":_vm.onMoveEnd,"change":function($event){return _vm.manipulateWidgets($event, 'GSI', item[1].name, 'add', item, index)}}},_vm._l((_vm.template['GSI'][item[0]].elements),function(value){return _c('div',{key:value.name,staticClass:"module",class:value.class,on:{"mouseover":function($event){_vm.hoveredElementId = 'GSI' + index},"mouseleave":function($event){_vm.hoveredElementId = null},"click":function($event){return _vm.addDetails(value.function_name, index, 'GSI')}}},[(value.function_type == 'generic')?_c('div',{staticClass:"widget-img",style:({
                                                height:
                                                    _vm.template['GSI'][item[0]].title.length == 0 ? '100%' : '7rem',
                                            })},[_c('img',{attrs:{"src":value.image,"alt":""}})]):_c('div',{staticClass:"widget-img",staticStyle:{"height":"7rem"}},[_c('img',{attrs:{"src":value.image,"alt":""}})]),_c('div',{staticClass:"widget-title"},[(_vm.template['GSI'][item[0]].title)?_c('p',[_vm._v(" "+_vm._s(_vm.template['GSI'][item[0]].title)+" ")]):_vm._e()]),(_vm.hoveredElementId == 'GSI' + index)?[_c('div',{staticClass:"w-100 h-100 action-buttons"},[_c('span',{staticClass:"background",attrs:{"id":"delete"},on:{"click":function($event){return _vm.manipulateWidgets(
                                                            $event,
                                                            'GSI',
                                                            item[1].name,
                                                            'remove',
                                                            value,
                                                            index
                                                        )}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/delete-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"delete","triggers":"hover","placement":"bottom"}},[_vm._v("Delete")]),_c('span',{staticClass:"background",attrs:{"id":"edit"},on:{"click":function($event){return _vm.OpenEditModal(value, index, 'GSI')}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/px/edit-icon.png"),"alt":""}})]),_c('b-tooltip',{attrs:{"target":"edit","triggers":"hover","placement":"bottom"}},[_vm._v("Edit")])],1)]:_vm._e()],2)}),0)],1)}),0),(_vm.totalPages('GSI') > 1)?_c('div',{staticClass:"mt-4 flex align-items-center justify-center gap-2"},_vm._l((_vm.totalPages('GSI')),function(page){return _c('div',{key:page,staticClass:"pagination cursor-pointer",style:({
                                    background:
                                        page === _vm.currentGSIPage
                                            ? _vm.appColors.canvas_color
                                            : _vm.appColors.canvas_text_color,
                                }),on:{"click":function($event){return _vm.handlePageChange('GSI', page)}}})}),0):_vm._e()])])]):_vm._e()])]),_c('div',{staticClass:"addmoduledetail"},[(_vm.openEditModal)?_c('AddDetailModal',{attrs:{"open":_vm.openEditModal,"clickedProfileFunction":_vm.clickedProfileFunction,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"group":_vm.group},on:{"close":function($event){_vm.openEditModal = false}}}):_vm._e(),(_vm.isEducation)?_c('EducationFeedEditModal',{attrs:{"dashboardId":_vm.dashboardId,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"clickedProfileFunction":_vm.clickedProfileFunction,"group":_vm.group},on:{"cancel":function($event){_vm.isEducation = false}}}):_vm._e(),(_vm.openCore3rdPartyEditModal)?_c('AddModuleDetailModal',{attrs:{"open":_vm.openCore3rdPartyEditModal,"group":_vm.group,"position":_vm.position,"pageData":_vm.pageData,"applicationFrame":_vm.applicationFrame,"dashboardId":_vm.dashboardId,"moduleId":_vm.moduleId},on:{"close":function($event){_vm.openCore3rdPartyEditModal = false}}}):_vm._e()],1),(_vm.loading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h1',{staticClass:"px-4 py-3 heading w-100"},[_vm._v("Dashboard Builder")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-dashboard-top-bar"},[_c('div',{staticClass:"d-flex w-10 justify-content-start"},[_c('div',{staticClass:"d-flex justify-content-start ms-3"},[_c('img',{staticStyle:{"width":"30px","height":"30px","filter":"invert(1) grayscale(100%) brightness(200%)"},attrs:{"src":require("@/assets/px/drag-and-drop/menu-icon.svg"),"alt":""}})])]),_c('div',{staticClass:"w-60 d-flex fw-semibold fs-4"},[_vm._v("Dashboard")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-header"},[_vm._v(" Hello, Adam Liviston "),_c('img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":require("@/assets/px/header-icons-images/hello.svg"),"alt":""}})])}]

export { render, staticRenderFns }